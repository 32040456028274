import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Container from "../components/container";
import FunctionBlock from "../components/functionBlock";
import styles from "./funkcje-systemu-crm.module.css";
import Carousel from "nuka-carousel";
import Img from "gatsby-image";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: {
        eq: "Prosty-CRM-Online-zarzadzanie-procesem-sprzedazy.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie2: file(relativePath: { eq: "prosty-crm-Kalendarz-i-zadania.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie3: file(
      relativePath: {
        eq: "Przyczyny-utraty-szans-sprzedazy-raporty-w-prostym-systemie-CRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie4: file(
      relativePath: {
        eq: "Raporty-w-prostym-systemie-CRM-lejek-szans-sprzedazy.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie5: file(
      relativePath: {
        eq: "aktywnosc-handlowcow-raporty-w-prostym-systemie-CRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie6: file(relativePath: { eq: "prosty-crm-Komunikator.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie7: file(
      relativePath: {
        eq: "Korzystaj-ze-wspolnej-bazy-wiedzy-o-kontrahentach.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie8: file(relativePath: { eq: "prosty-crm-Dynamiczne-pulpity.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie9: file(
      relativePath: { eq: "prosty-crm-Zarzadzanie-uprawnieniami.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Funkcje = ({ data }) => {
  return (
    <Layout>
      <SEO title="Funkcje systemu CRM. Błyskawiczne usprawnienie firmy ⚡"
      description="Funkcje systemu CRM • Prosty system CRM dla dynamicznych zespołów? • Szanse sprzedaży, kontrahenci, kalendarz i zadania, raporty, komunikator." 
      keywords={["funkcje systemu crm"]}/>
      <Hero maxHeight="280px" justifyContent="center">
        <h1>Poznaj funkcje systemu CRM</h1>
        <p>Stworzony, by wspierać zespoły w codziennej pracy</p>
      </Hero>
      <Container maxWidth="1400px">
        <div className={styles.funkcjeContainer}>
          <FunctionBlock
            title="Zarządzanie szansami sprzedaży"
            img={data.zdjecie1.childImageSharp.fluid}
            alts={["funkcje systemu crm szanse sprzedaży"]}
            learnMore="/sprzedaz-w-crm/"
          >
            <Link to="/sprzedaz-w-crm/">Zarządzając szansami sprzedaży</Link> w
            systemie CRM,{" "}
            <strong>
              usprawnisz skomplikowane procesy sprzedażowe. Wyznaczaj priorytety
            </strong>{" "}
            i gromadź ważne informacje w jednym miejscu.{" "}
            <strong>Monitoruj postępy</strong> w nawiązywaniu relacji z
            klientami,{" "}
            <strong>
              odpowiadaj na ich potrzeby i zyskaj satysfakcję ze wzrostu
              sprzedaży w firmie!
            </strong>{" "}
            Jakie jeszcze funkcje systemu CRM sprawią, że Twój biznes wejdzie na
            wyższy poziom?
          </FunctionBlock>
          <FunctionBlock
            title="Kalendarz i zadania"
            img={data.zdjecie2.childImageSharp.fluid}
            alts={["funkcje systemu crm kalendarz"]}
            learnMore="/kalendarz-i-zadania/"
          >
            <Link to="/kalendarz-i-zadania/">Kalendarz i zadania</Link> w
            QuickCRM pomogą Ci być na bieżąco ze wszystkim.{" "}
            <strong>Planuj, koordynuj, monitoruj.</strong> Zlecaj zadania
            konkretnym pracownikom i przypisuj je do konkretnych firm. Trzymaj
            rękę na pulsie —{" "}
            <strong>zapanuj nad realizacją działań w firmie!</strong>
          </FunctionBlock>
          <FunctionBlock title="Raporty" learnMore="/raporty/">
            <div
              style={{
                margin: "10px 0"
              }}
            >
              <Carousel
                wrapAround={true}
                dragging={false}
                autoplay={true}
                renderCenterLeftControls={({ previousSlide }) => (
                  <FaChevronLeft size="25px" onClick={previousSlide} />
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <FaChevronRight size="25px" onClick={nextSlide} />
                )}
              >
                <Img fluid={data.zdjecie3.childImageSharp.fluid} />

            
                <Img fluid={data.zdjecie4.childImageSharp.fluid} />
                <Img fluid={data.zdjecie5.childImageSharp.fluid} />
              </Carousel>
            </div>
            Wyobrażasz sobie prowadzenie skutecznej sprzedaży bez jej analizy?
            My też nie. Dlatego QuickCRM zapewnia{" "}
            <Link to="/raporty/">raporty</Link> niezbędne w pracy każdej firmy.{" "}
            <strong>
              Analizuj aktywność handlowców, lejek szans sprzedaży i przyczyny
              utraty potencjalnych klientów.
            </strong>{" "}
            Udoskonalaj procesy sprzedaży na podstawie łatwo dostępnych danych!
          </FunctionBlock>
          <FunctionBlock
            title="Komunikator"
            img={data.zdjecie6.childImageSharp.fluid}
            alts={["funkcje systemu crm komunikator"]}
            imgMaxWidth={300}
            learnMore="/komunikator/"
          >
            Prosty CRM <strong>powinien zapewniać łatwą komunikację.</strong> Z
            tego powodu QuickCRM został wyposażony w{" "}
            <Link to="/komunikator/">komunikator</Link> umożliwiający sprawną
            wymianę informacji. Za pomocą komunikatora
            <strong>skontaktujesz się ze swoimi pracownikami</strong> bez
            konieczności korzystania z zewntrznych aplikacji. Wysyłane
            wiadomości możesz powiązać z zadaniem lub firmą. Masz możliwość
            prowadzenia rozmów grupowych i wysyłania plików. W razie potrzeby
            szybko odnajdziesz wysłaną wiadomość, ponieważ{" "}
            <strong>
              wszystkie konwersacje są bezpiecznie przechowywane w systemie.
            </strong>
          </FunctionBlock>
          <FunctionBlock
            title="Kartoteki kontrahentów"
            img={data.zdjecie7.childImageSharp.fluid}
            alts={["funkcje systemu crm kartoteki kontrahentów"]}
            learnMore="/kartoteki-kontrahentow/"
          >
            System CRM pełni między innymi funkcję bazy wiedzy. To{" "}
            <strong>
              dzięki gromadzeniu informacji w jednym miejscu i ich
              uporządkowaniu wykonywanie codziennych obowiązków może przebiegać
              sprawniej.
            </strong>{" "}
            Takim miejscem w QuickCRM jest{" "}
            <Link to="/kartoteki-kontrahentow/">kartoteka kontrahenta</Link>,
            która{" "}
            <strong>
              zawiera dane niezbędne do prowadzenia skutecznej komunikacji
            </strong>{" "}
            z Twoimi partnerami biznesowymi!
          </FunctionBlock>
          <FunctionBlock
            title="Dynamiczne pulpity"
            img={data.zdjecie8.childImageSharp.fluid}
            alts={["funkcje systemu crm pulpit"]}
            learnMore="/pulpit-pracownika/"
          >
            <Link to="/pulpit-pracownika/">Pulpit</Link> w QuickCRM jest
            miejscem, w którym{" "}
            <strong>
              użytkownik systemu ma możliwość gromadzenia najważniejszych dla
              siebie informacji.
            </strong>{" "}
            Może on zostać dowolnie zmodyfikowany i zapewniać w ten sposób
            wysoki komfort użytkowania systemu. Na pulpicie mogą pojawić się
            różne informacje, w zależności od obejmowanego stanowiska czy też
            preferencji. Daje to gwarancję, że{" "}
            <strong>wszystkie działania będą przebiegały sprawniej</strong>, a
            ważne dane będą zawsze pod ręką.
          </FunctionBlock>
          <FunctionBlock
            title="Zarządzanie uprawnieniami"
            img={data.zdjecie9.childImageSharp.fluid}
            alts={["funkcje systemu crm uprawnienia"]}
            learnMore="/zarzadzanie-uprawnieniami/"
          >
            Aby zapewnić swoim pracownikom dostęp do odpowiednich dla nich
            elementów systemu, wykorzystaj zarządzanie uprawnieniami.{" "}
            <strong>
              Decyduj, którzy użytkownicy systemu mogą wykonywać konkretne
              czynności i odczytywać konkretne informacje.{" "}
            </strong>
            W ten sposób <strong> zwiększysz bezpieczeństwo danych</strong> i
            ułatwisz pracownikom codzienną pracę — dostęp tylko do niezbędnych
            im informacji ułatwi poruszanie się po systemie.
          </FunctionBlock>
        </div>
      </Container>
    </Layout>
  );
};

export default Funkcje;
